/**
 * ページャ
 */

import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import {
  BsChevronDoubleLeft, BsChevronLeft,
  BsChevronRight, BsChevronDoubleRight,
 } from "react-icons/bs";

const Pager = ({ pageInfo, pathPrefix }) => {
  const { currentPage, hasPreviousPage, hasNextPage, pageCount } = pageInfo
  const pathFirst = pathPrefix
  const pathPrev = currentPage > 2 ? `${pathPrefix}${currentPage - 2}/` : pathPrefix
  const pathNext = `${pathPrefix}${currentPage}/`
  const pathLast = `${pathPrefix}${pageCount - 1}/`

  return (
    <div className="pager flex-row-center">
      {hasPreviousPage ? <Link to={pathFirst} className="pager-prev"><BsChevronDoubleLeft /></Link> :
        <span className="pager-prev" />}
      {hasPreviousPage ? <Link to={pathPrev} className="pager-prev"><BsChevronLeft /></Link> :
        <span className="pager-prev" />}
      <div className="pager-current">{currentPage}</div>
      {hasNextPage ? <Link to={pathNext} className="pager-next"><BsChevronRight /></Link>:
      <span className="pager-next" />}
      {hasNextPage ? <Link to={pathLast} className="pager-next"><BsChevronDoubleRight /></Link> :
        <span className="pager-next" />}
    </div>
  )
}

Pager.propTypes = {
  pageInfo: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    hasNextPage: PropTypes.bool.isRequired,
    pageCount: PropTypes.number.isRequired,
    itemCount: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    hasPreviousPage: PropTypes.bool.isRequired,
  }),
  pathPrefix: PropTypes.string.isRequired,
}

export default Pager
