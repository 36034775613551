/**
 * パンくずリスト
 */

import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { BsHouse, BsSlash } from "react-icons/bs"

const Breadcrumbs = ({ parents, leaf }) => {
  const sep = (<span className="pl-2 pr-2"><BsSlash /></span>)
  const className = `color-base`
  return (
    <nav className="breadcrumbs flex-row-wrap mb-3 pl-2 pr-2">
      <Link to="/" className={className}> <BsHouse /> トップ </Link>
      {sep}
      {parents.map((item) => (
        <Fragment key={item.path}>
          <Link to={item.path} className={className}>
            {item.label}
          </Link>
          {sep}
        </Fragment>
      ))}
      <span>{leaf}</span>
    </nav>
  )
}

Breadcrumbs.propTypes = {
  parents: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  })).isRequired,
  leaf: PropTypes.string.isRequired,
}

export default Breadcrumbs
