/**
 * サムネール付き投稿の一覧
 *
 * `gatsby-transformer-remark` の `excerpt` は `…` だけが出力されたり
 * 文字数が多すぎたりして使い勝手が悪いので独自の `excerpt` を利用しています
 *
 * @see gatsby-transformer-remark/src/extend-node-type.js
 */

import React from "react"
import PropTypes from "prop-types"
import { Link, graphql, useStaticQuery } from "gatsby"
import truncate from "underscore.string/truncate"
import visit from "unist-util-visit"

const TOTAL_COUNT_THRESHOLD = 4

const PRUNE_LENGTH_LONG = 70
const PRUNE_LENGTH_SHORT = 40

const PostList = ({ heading, posts, totalCount, morePath, excerptType }) => {

  // サムネールのフォールバック画像を取得する
  const result = useStaticQuery(graphql`
    query PostList {
      file(relativePath: {eq: "thumbnail-160x90.png"}) {
        publicURL
      }
    }
  `)
  const fallbackImage = result.file.publicURL

  // 指定がないときは `long` にフォールバックする
  let pruneLength = excerptType === `short` ? PRUNE_LENGTH_SHORT : PRUNE_LENGTH_LONG

  if (posts.length < 1) {
    return null
  }

  return (
    <div>
      <h2>{heading}</h2>
      {posts.map(p => {
        const title = p.fields.title
        const excerpt = genExcerpt(p.childMarkdown.childMarkdownRemark.excerptAst, pruneLength)
        return (
          <Link key={p.id} to={p.fields.path} className="a-block color-base mb-2">
            <h3 className="mb-1">{title}</h3>
            <div className="l-pc_tablet-flex-row-top-between">
              <div className="l-pc_tablet-pr-3">
                <p className="mb-1">{excerpt}</p>
                <div className="mb-1"><time>{p.fields.date}</time></div>
              </div>
              {p.fields.thumbnail ?
                <img src={p.fields.thumbnail} alt={title} width={160} height={90} /> :
                <img src={fallbackImage} alt={title} className="border-1" />
              }
            </div>
          </Link>
        )
      })}
      {
        (totalCount >= TOTAL_COUNT_THRESHOLD) && morePath ? (
          <div className="t-right">
            <Link to={morePath}>一覧を見る</Link>
          </div>
        ) : ``
      }
    </div>
  )
}

/**
 * スペースを挟むべき要素
 */
const SpaceMarkdownNodeTypesSet = new Set([
  `paragraph`,
  `heading`,
  `tableCell`,
  `break`,
])

/**
 * excerptAst から excerpt の文字列を生成する
 *
 * @see gatsby-transformer-remark/src/extend-node-type.js
 */
const genExcerpt = (ast, pruneLength) => {
  const excerptNodes = []
  visit(
    ast,
    node => {
      if (node.type === `text` || node.type === `inlineCode`) {
        excerptNodes.push(node.value)
      } else if (SpaceMarkdownNodeTypesSet.has(node.type)) {
        excerptNodes.push(` `)
      }
    }
  )

  const excerptText = excerptNodes.join(` `).trim()
  return truncate(excerptText, pruneLength, `…`)
}

const AstElementShape = {
  type: PropTypes.string.isRequired,
}
AstElementShape.children = PropTypes.arrayOf(PropTypes.shape(AstElementShape))
const AstElement = PropTypes.shape(AstElementShape)

PostList.propTypes = {
  heading: PropTypes.string.isRequired,
  posts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    fields: PropTypes.shape({
      title: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      thumbnail: PropTypes.string.isRequired,
      ogpImage: PropTypes.string.isRequired,
    }),
    childMarkdown: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        excerptAst: AstElement,
      }),
    }),
  })),
  totalCount: PropTypes.number.isRequired,
  morePath: PropTypes.string,
  excerptType: PropTypes.string,
}

export default PostList

export const query = graphql`
  fragment PostTeaser on StrapiPost {
    id
    fields {
      title
      path
      date(formatString: "YYYY.MM.DD")
      thumbnail
      ogpImage
    }
    childMarkdown {
      childMarkdownRemark {
        excerptAst(pruneLength: 200)
      }
    }
  }
`
